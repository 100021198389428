<template>
  <div class="history">
    <div class="title"> <span>对公提现-历史记录</span></div>
    <div class="table-box">
      <div class="row"><el-input v-model="expressid" placeholder="请输入回执单号或流水号"></el-input><el-button type="primary"  @click="search">搜索</el-button></div>
      <el-table :data="currentList"  style="width: 100%" :header-cell-style="{textAlign: 'center', color: '#333333'}" :cell-style="{ textAlign: 'center',color: '#555555' }">
        <el-table-column prop="id" label="流水号" width="80"></el-table-column>
        <el-table-column prop="teamowner" label="用户名" width="110"></el-table-column>
        <el-table-column label="金额" width="120"><template slot-scope="scope">{{disponse(scope.row.points)}}</template></el-table-column>
        <el-table-column prop="accountname" label="户名" width="150"></el-table-column>
        <el-table-column prop="bank" label="开户行"></el-table-column>
        <el-table-column prop="accountid" label="银行账号"></el-table-column>
        <el-table-column prop="phone" label="联系电话" ></el-table-column>
        <el-table-column label="日期" width="180"><template slot-scope="scope">{{format(scope.row.updated)}}</template></el-table-column>
        <el-table-column prop="transno" label="回执单号" width="180"></el-table-column>
      </el-table>
      <el-pagination v-if="records.length > 0" :page-size="10" @current-change="currentChange" layout="prev, pager, next" :total="records.length"></el-pagination>
    </div>
  </div>
</template>

<script>
import { teamerDrawlog } from '@/api/withdraw.js'
export default {
  name: 'history',
  data () {
    return {
      expressid: '',
      page: 1,
      records: [],
      currentList: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const res = await teamerDrawlog()
      if (res.code === 0) {
        this.records = res.records
        this.records = this.records.filter(e => {
          if (this.expressid) {
            return e.status === 1 && (e.transno === this.expressid || e.accountid === this.expressid)
          } else {
            return e.status === 1
          }
        })
        this.currentList = this.records.slice((this.page - 1) * 10, this.page * 10)
      }
    },
    search () {
      this.page = 1
      this.getList()
    },
    currentChange (p) {
      this.page = p
      this.getList()
    },
    disponse (n) {
      return n / 100
    },
    format (t) {
      const curTime = new Date(t * 1000)
      const year = curTime.getFullYear()
      const month = curTime.getMonth() + 1 < 10 ? '0' + (curTime.getMonth() + 1) : curTime.getMonth() + 1
      const day = curTime.getDate() < 10 ? '0' + curTime.getDate() : curTime.getDate()
      // console.log(year, month, day)
      return '' + year + month + day
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>
<style lang="less" scoped>
  .history {
    padding: 26px 0px 42px;
    box-sizing: border-box;
    .title{
      display: flex;
      justify-content: left;
      padding: 0 20px;
      align-items: center;
      margin-bottom: 36px;
      span:first-child{
        color: #333333;
        font-size: 20px;
        font-weight: 500;
      }
    }
    .table-box{
      padding: 20px 20px 100px 20px;
      box-shadow: 0px 0px 6px 1px rgba(48,78,86,0.1600);
      // min-height: calc(100% - 100px);
      min-height: 620px;
      position: relative;
      .row{
        margin: 0;
        .el-input{
          width: 358px;
          /deep/.el-input__inner{
            border-radius: 2px 0px 0px 2px;
            border-right: 0;
          }
          /deep/.el-input__inner:focus{
            border-color: #3775F6;
          }
        }
        .el-button{
          position: relative;
          left: -2px;
          width: 62px;
          border-radius:0px 2px 2px 0px;
          background: #3775F6;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .el-table{
        margin-top: 20px;
      }
      .el-pagination{
        position: absolute;
        bottom: 20px;
      }
    }
  }
</style>
